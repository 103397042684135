import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { cookieHelper } from 'src/app/core/helpers/cookieHelper';
import { ReadingSettingsDataService } from './core/services/commonServices/reading-settings-data.service';
import { SchoolDataService } from './core/services/school-data.service';
const content = require('src/assets/setupData.json');
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'schoolPublicUser';
  favIcon: HTMLLinkElement | any = document.querySelector("#appIcon");
  schoolData:any;
  doaminName:string;
  constructor(private readingsettingData: ReadingSettingsDataService, private schoolDataService: SchoolDataService,private readingSettingsData: ReadingSettingsDataService,
    private cookieHelper: cookieHelper,public titleService:Title) {
    this.doaminName=this.readingSettingsData.getSchoolDomain();
    this.schoolDataService.getSchoolData().subscribe((data: any) => 
    {this.schoolData = data
      this.titleService.setTitle(this.schoolData.name);
      this.favIcon.href=`${this.doaminName}${this.schoolData.logo}`
    });
    
        
  
   
  }
  
}
