<!-- from CSV -->
<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <title>{{schoolData.name}}</title>
  <link rel="shortcut icon" href="{{doaminName+schoolData.logo}}" />

  <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
</head>

<body>
  <div id="wrapper">
    <!--header-->
    <app-header [schoolData]="schoolData"></app-header>
    <!--hidden menue-->
    <app-hidden-tab-right></app-hidden-tab-right>
    <!-- NavHolder -->
    <app-navbar></app-navbar>
    <div id="mainholder">
      <div class="wrapper">

        <!--main route  componnent -->
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <!--footer componnent-->
  <app-footer [schoolData]="schoolData"></app-footer>

  <!--end sidebar hide -->


</body>

</html>
